@mylayout-content-min-width : 540px;
@mylayout-data-min-width : 325px;
@mylayout-data-min-width-small : 275px;

.mylayout-left-right , .mylayout-right-left {
	display :flex;
	flex-wrap: nowrap;
	position : relative;
	.clearfix();

	.sticky {
		position : sticky;
		top : 8px;
	}
	.mylayout-left {
		margin-right : 12px;
		position : relative;
	}
	.mylayout-right {
		position : relative;
	}

	@media (max-width : @screen-tablet) {
		&.responsive {
			&.reverse {
				flex-direction: column-reverse;
				.mylayout-left {
					margin-top : 24px;
				}
			}
			flex-direction: column;
			.mylayout-left {
				margin-right : 0;
				margin-bottom : 24px;
			}
		}
		.mylayout-hidden-sm {
			display : none !important;
		}

		.sticky {
			position: static;
		}
	}
}

.mylayout-left-right {
	.mylayout-left {
		width : @mylayout-data-min-width;
		&.small {
			width : @mylayout-data-min-width-small;
		}
	}
	.mylayout-right {
		flex-grow: 1;
		min-width : @mylayout-content-min-width;
	}
	&.responsive {
		.mylayout-left {
			min-width : @mylayout-data-min-width;
			&.small {
				min-width : @mylayout-data-min-width-small;
			}
		}
	}

	@media (max-width : @screen-tablet) {
		&.responsive {
			.mylayout-left {
				width : 100%;
				min-width : unset;
			}
			.mylayout-right {
				min-width : auto;
			}
		}
	}
}

.mylayout-right-left {
	.mylayout-right {
		width : @mylayout-data-min-width;
		&.small {
			width : @mylayout-data-min-width-small;
		}
	}
	.mylayout-left {
		flex-grow: 1;
		min-width : @mylayout-content-min-width;
	}
	&.responsive {
		.mylayout-right {
			min-width : @mylayout-data-min-width;
			&.small {
				min-width : @mylayout-data-min-width-small;
			}
		}
	}

	@media (max-width : @screen-tablet) {
		&.responsive {
			.mylayout-right {
				width : 100%;
				min-width : unset;
			}
			.mylayout-left {
				min-width : auto;
			}
		}
	}
}


.row {
	.clearfix();
	width : 100%;
	.left {
		float : left;
	}
	.right {
		float : right;
	}
}
