@import url(https://fonts.googleapis.com/css?family=Prompt);
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.user-menu-dropdown[data-v-4b87f2f4] {
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
}
.user-menu-dropdown[data-v-4b87f2f4]:hover {
  background-color: #f5f5f5;
}
.user-menu-trigger[data-v-4b87f2f4] {
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.user-menu-trigger .user-avatar[data-v-4b87f2f4] {
  margin-right: 4px;
  vertical-align: middle;
}
.user-menu[data-v-4b87f2f4] {
  padding-top: 0;
  padding-bottom: 0;
}
.user-menu .ant-dropdown-menu-item[data-v-4b87f2f4] {
  padding: 10px 12px;
}
.user-menu .ant-dropdown-menu-item.user-profile-menu-item[data-v-4b87f2f4] {
  padding: 0;
  cursor: default;
}
.user-profile[data-v-4b87f2f4] {
  color: #fff;
  padding: 24px 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-image: url(../img/bg-user-profile.dd4b2826.png);
  background-size: cover;
  width: 300px;
  height: 100px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.user-menu-overlay {
  -webkit-box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
          box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app-brand[data-v-01aa108a] {
  position: relative;
  padding: 8px 0;
  overflow: hidden;
  white-space: nowrap;
}
.app-brand .brand-logo[data-v-01aa108a] {
  margin: 0 16px;
}
.app-brand .brand-title[data-v-01aa108a] {
  display: block;
  margin-top: 16px;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 48px;
  font-size: 2em;
  font-weight: 600;
  color: #D22027;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
}
.app-brand .brand-title-collapsed[data-v-01aa108a] {
  margin-top: 0 16px;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 48px;
  font-size: 1.2em;
  font-weight: 600;
  color: #D22027;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
}
.company-profile[data-v-01aa108a] {
  background-color: #499c91;
  color: #fff;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  padding: 20px 12px;
  margin: 20px auto 20px;
  border-radius: 4px;
  width: calc(250px - 36px);
}
.company-profile .card-title[data-v-01aa108a] {
  font-size: 1.2em;
  font-weight: normal !important;
  margin-bottom: 2px;
  margin-left: 4px;
}
.company-profile .card-title a[data-v-01aa108a] {
  color: #fff;
  text-decoration: none !important;
}
.company-profile .card-image .image-loader[data-v-01aa108a] {
  background-color: white;
}
.tunjai-menu[data-v-01aa108a] {
  margin-bottom: 24px;
}
.tunjai-menu .ant-menu-item[data-v-01aa108a] {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.tunjai-menu .ant-menu-item[data-v-01aa108a]:hover {
  background-color: #fff2f0;
}
footer[data-v-01aa108a] {
  font-size: 0.8em;
  color: #595959;
  text-align: right;
  padding-right: 8px;
  padding-bottom: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.tunjai-page-loading {
  min-height: 300px;
}
.tunjai-page-loading .ant-spin-blur {
  opacity: 0.4;
}
.tunjai-page-loading .ant-spin-dot i {
  background-color: #1890ff;
}
.tunjai-page-loading .ant-spin-text {
  font-size: 2em;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  margin-top: 14px;
  color: #1890ff;
}
#tunjai_content {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100%;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.container[data-v-0b199ff1] {
  position: relative;
  padding: 100px 0px 144px;
  min-height: 100%;
  text-align: center;
  background-image: url(../img/bg_login.f3c3d1a5.png);
  background-repeat: no-repeat;
  background-position: right bottom;
}
.container .main[data-v-0b199ff1] {
  padding: 0 16px;
  height: 100%;
}
.container.mobile[data-v-0b199ff1] {
  padding-top: 24px;
  background-size: contain;
}
footer[data-v-0b199ff1] {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px 16px 16px;
  background-color: #033636;
  color: #fff;
  border-top: solid 1px #d9d9d9;
}
#tunjai_content[data-v-0b199ff1] {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100%;
}
.privacy-policy[data-v-0b199ff1] {
  font-size: 1.1em;
  color: #FFF;
  text-decoration: underline;
}
.mobile .privacy-policy[data-v-0b199ff1] {
  font-size: 1em;
}
a[data-v-0b199ff1]:hover {
  color: #0f85ff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.error-container[data-v-009d9454] {
  margin: 0 16px;
}
.error-detail[data-v-009d9454] {
  text-align: left;
  font-size: 13px;
  color: #8c8c8c;
  margin: 32px 24px 32px;
  padding: 8px 16px;
  border: 1px dashed #8c8c8c;
  border-radius: 4px;
}
.error-location[data-v-009d9454] {
  font-size: 14px;
  margin-top: 32px;
}
.error-action[data-v-009d9454] {
  margin-top: 16px;
}
.error-action a[data-v-009d9454] {
  min-width: 200px;
}
.error-card[data-v-009d9454] {
  -webkit-box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
  max-width: 750px;
  width: 100%;
  background: #fff;
  margin: 64px auto 32px;
  text-align: center;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  padding: 16px 24px 42px;
}
.error-code[data-v-009d9454] {
  font-size: 6em;
  font-weight: 100;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  -webkit-box-shadow: 0 0 10px #29d, 0 0 5px #29d;
          box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


/* stylelint-disable at-rule-no-unknown */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #f5f5f5;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum';
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #40a9ff;
}
a:active {
  color: #096dd9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::-moz-selection {
  color: #fff;
  background: #14837B;
}
::selection {
  color: #fff;
  background: #14837B;
}
.clearfix {
  zoom: 1;
}
.clearfix::before,
.clearfix::after {
  display: table;
  content: '';
}
.clearfix::after {
  clear: both;
}
.clearfix::before,
.clearfix::after {
  display: table;
  content: '';
}
.clearfix::after {
  clear: both;
}
html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
body {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.65);
  min-width: 320px;
}
hr {
  border: none;
  border-top: 1px solid #bfbfbf;
}
.application-container {
  height: 100%;
  width: 100%;
  min-height: 100%;
}
.border-primary {
  border: 1px solid #14837B;
  border-radius: 4px;
  -webkit-box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
          box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:-webkit-autofill:hover,
input:-webkit-autofill:-webkit-autofill:focus,
input:-webkit-autofill:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
ul,
ol {
  padding-left: 0;
}
.card-image-with-text-box .card-image {
  float: left;
  margin-right: 8px;
}
.card-image-with-text-box .card-content .card-title {
  font-weight: 600;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-image-with-text-box .card-content .card-description {
  font-size: 0.9em;
}
#nprogress .bar {
  background: #14837B;
  height: 4px;
}
#nprogress .peg {
  -webkit-box-shadow: 0 0 10px #14837B, 0 0 5px #14837B;
          box-shadow: 0 0 10px #14837B, 0 0 5px #14837B;
}
.page {
  min-height: 300px;
}
.page-action-right {
  text-align: right;
  margin-bottom: 8px;
}
.next-button {
  margin: 10px 0;
}
.page-padding {
  padding: 16px 16px 50px;
}
.mobile .page-padding,
.tablet .page-padding {
  padding: 8px 8px 24px;
}
.page-padding-excluded {
  margin: -16px -16px -32px;
}
.mobile .page-padding-excluded,
.tablet .page-padding-excluded {
  margin: -8px -8px -24px;
}
.mybreadcrumb {
  padding: 16px 16px 8px;
}
.mobile .mybreadcrumb,
.tablet .mybreadcrumb {
  padding: 16px 8px 8px;
}
.mobile .mybreadcrumb .mybreadcrumb-item-ellipsis,
.tablet .mybreadcrumb .mybreadcrumb-item-ellipsis {
  display: inline-block;
  vertical-align: bottom;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.btn-page-action {
  min-width: 200px;
  margin-bottom: 16px;
}
.mobile .btn-page-action {
  min-width: 150px;
}
.btn-submit {
  min-width: 200px;
}
.mobile .btn-submit {
  min-width: 100px;
}
.valign-top {
  vertical-align: top;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-bold {
  font-weight: 600;
}
.text-info {
  color: #1890ff !important;
}
a.text-info:hover {
  color: #0076e4 !important;
}
.text-success {
  color: #52c41a !important;
}
a.text-success:hover {
  color: #3f9714 !important;
}
.text-warning {
  color: #faad14 !important;
}
a.text-warning:hover {
  color: #d69005 !important;
}
.text-error {
  color: #f5222d !important;
}
a.text-error:hover {
  color: #da0a15 !important;
}
.text-muted {
  color: #8c8c8c !important;
}
a.text-muted:hover {
  color: #737373 !important;
}
.text-secondary {
  color: #D22027 !important;
}
a.text-secondary:hover {
  color: #a6191f !important;
}
.text-title,
.text-primary {
  color: #14837B !important;
}
a.text-title:hover,
a.text-primary:hover {
  color: #0d5751 !important;
}
.text-value {
  color: #14837B;
  -webkit-text-decoration: underline dashed;
          text-decoration: underline dashed;
}
.hidden {
  display: none;
}
.multiline {
  white-space: pre-wrap;
  word-break: break-word;
}
.page-section-title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #D22027;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 24px;
}
.page-section-title .page-section-action {
  float: right;
  font-family: Helvetica, Arial, sans-serif;
}
.page-section-description {
  font-size: 0.6em;
  color: #8c8c8c;
  font-weight: 400;
}
span.page-section-description {
  margin-left: 16px;
}
.content-with-details {
  margin-bottom: 24px;
}
.content-with-details:last-child {
  margin-bottom: 0;
}
.content-with-details .content-header {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #D22027;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 4px;
}
.content-with-details .content-row {
  zoom: 1;
  margin-bottom: 8px;
  padding: 0 8x;
}
.content-with-details .content-row::before,
.content-with-details .content-row::after {
  display: table;
  content: '';
}
.content-with-details .content-row::after {
  clear: both;
}
.content-with-details .content-row::before,
.content-with-details .content-row::after {
  display: table;
  content: '';
}
.content-with-details .content-row::after {
  clear: both;
}
.content-with-details .content-row::before,
.content-with-details .content-row::after {
  display: table;
  content: '';
}
.content-with-details .content-row::after {
  clear: both;
}
.content-with-details .content-row::before,
.content-with-details .content-row::after {
  display: table;
  content: '';
}
.content-with-details .content-row::after {
  clear: both;
}
.content-with-details .content-row .content-label {
  color: #1890ff;
  width: 185px;
  margin-right: 8px;
  text-align: right;
  float: left;
}
.content-with-details .content-row .content-label::after {
  content: " : ";
}
@media (min-width: 992px) {
  .content-with-details .content-row .content-label {
    width: 250px;
  }
}
.content-with-details .content-row .content-value {
  float: left;
  width: 500px;
  width: calc(100% - 185px - 8px);
}
@media (min-width: 992px) {
  .content-with-details .content-row .content-value {
    width: calc(100% - 250px - 8px);
  }
}
.content-with-details .content-row .content-value.no-label {
  margin-left: 193px;
}
@media (min-width: 992px) {
  .content-with-details .content-row .content-value.no-label {
    margin-left: 258px;
  }
}
.content-with-details .content-row .anticon {
  color: #14837B;
  padding-right: 4px;
}
.content-with-details .content-row a {
  -webkit-text-decoration: underline dashed;
          text-decoration: underline dashed;
}
.content-with-details .content-row:last-child {
  margin-bottom: 0;
}
.content-with-details .separator {
  border: none;
  border-top: 1px solid #bfbfbf;
  margin: 16px 0;
}
.cursor-pointer {
  cursor: pointer;
}
ul.no-style {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.no-style > li {
  margin-bottom: 4px;
}
ul.no-style > li:last-child {
  margin-bottom: 0;
}
.mysteps-actions {
  margin: 24px 0 16px;
  text-align: right;
}
.mysteps-actions .ant-btn {
  min-width: 200px;
}
body[data-device=mobile] .mysteps-actions .ant-btn {
  min-width: 120px;
}
.mytooltip {
  min-width: 100px;
  max-width: 300px;
}
.mytooltip-trigger,
.mypopover-trigger {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.mytooltip-white .ant-tooltip-arrow::before {
  background-color: #fff;
}
.mytooltip-white .ant-tooltip-inner {
  background-color: #fff;
  color: #616161;
}
.mytooltip-warning .ant-tooltip-arrow::before {
  background-color: #faad14;
}
.mytooltip-warning .ant-tooltip-inner {
  background-color: #faad14;
  color: #fff;
}
.mytooltip-error .ant-tooltip-arrow::before {
  background-color: #f5222d;
}
.mytooltip-error .ant-tooltip-inner {
  background-color: #f5222d;
  color: #fff;
}
.center-block {
  margin-left: auto;
  margin-right: auto;
}
.ql-toolbar {
  line-height: 1.15;
}
.mobile .mytab-menu .ant-menu-item .anticon {
  display: none;
}
.mytab-content {
  background: #fff;
  min-height: 150px;
  zoom: 1;
}
.mytab-content::before,
.mytab-content::after {
  display: table;
  content: '';
}
.mytab-content::after {
  clear: both;
}
.mytab-content::before,
.mytab-content::after {
  display: table;
  content: '';
}
.mytab-content::after {
  clear: both;
}
.mytab-content::before,
.mytab-content::after {
  display: table;
  content: '';
}
.mytab-content::after {
  clear: both;
}
.mytab-content::before,
.mytab-content::after {
  display: table;
  content: '';
}
.mytab-content::after {
  clear: both;
}
.mytab-section-title {
  color: #D22027;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  margin-bottom: 12px;
}
.mytab-section-title .mytab-section-description {
  font-size: 0.8em;
  margin-top: 4px;
  color: #8c8c8c;
  font-family: Helvetica, Arial, sans-serif;
  margin-left: 16px;
}
.mybutton-group-vertical > button {
  width: 100%;
  border-radius: 0;
  padding-top: 8px;
  padding-bottom: 9px;
  height: auto;
  margin-bottom: -1px;
}
.mybutton-group-vertical > button:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.mybutton-group-vertical > button:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 0;
}
.mybutton-group-vertical > button:active,
.mybutton-group-vertical > button:hover,
.mybutton-group-vertical > button:focus {
  z-index: 1;
}
.ant-form input.ant-input,
.ant-form .ant-select,
.ant-form textarea.ant-input {
  max-width: 450px;
}
.ant-form .ant-input-affix-wrapper {
  max-width: 450px;
}
.ant-form .ant-input-affix-wrapper input.ant-input {
  width: 100%;
}
.ant-form hr {
  border-top: 1px solid #d9d9d9;
  margin: 0 auto 24px;
  width: 80%;
}
.mobile .ant-form hr {
  width: 100%;
}
.ant-form.ant-form-vertical hr {
  width: 100%;
}
.ant-form.ant-form-vertical .ant-form-item.display-only {
  margin-bottom: 8px;
}
.ant-form .myform-expandable-pane {
  padding-top: 8px;
}
@media (max-width: 575px) {
  .ant-form.ant-form-inline .ant-form-item {
    margin-bottom: 12px;
  }
  .ant-form.ant-form-inline .ant-row.myform-expandable-row {
    display: block;
    margin-bottom: 0;
  }
  .ant-form.ant-form-inline .myform-expandable-pane {
    padding: 12px 16px;
    border: 1px dashed #1890ff;
    border-radius: 4px;
  }
}
.myform-label-col {
  width: 175px;
  float: left;
}
@media (min-width: 1599px) {
  .myform-label-col {
    width: 225px;
  }
}
@media (max-width: 575px) {
  .myform-label-col {
    width: 100%;
    float: none;
  }
}
.ant-modal-body .myform-label-col {
  width: 150px;
}
@media (max-width: 575px) {
  .ant-modal-body .myform-label-col {
    width: 100%;
  }
}
.myform-wrapper-col {
  width: 500px;
  width: calc(100% - 175px);
  float: left;
}
@media (min-width: 1599px) {
  .myform-wrapper-col {
    width: calc(100% - 225px);
  }
}
@media (max-width: 575px) {
  .myform-wrapper-col {
    width: 100%;
    float: none;
  }
}
.ant-modal-body .myform-wrapper-col {
  width: calc(100% - 150px);
}
@media (max-width: 575px) {
  .ant-modal-body .myform-wrapper-col {
    width: 100%;
  }
}
.myform-wrapper-offset-col {
  margin-left: 175px;
}
@media (max-width: 575px) {
  .myform-wrapper-offset-col {
    margin-left: 0;
  }
}
@media (min-width: 1599px) {
  .myform-wrapper-offset-col {
    margin-left: 225px;
  }
}
.ant-modal-body .myform-wrapper-offset-col {
  margin-left: 150px;
}
@media (max-width: 575px) {
  .ant-modal-body .myform-wrapper-offset-col {
    margin-left: 0;
  }
}
.myinput-number.ant-input-number {
  width: 125px;
}
.myinput-number.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}
.myinput-number.ant-input-number .ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 50%;
}
.myinput-number.ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler-up:hover,
.myinput-number.ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler-down:hover {
  height: 50% !important;
}
.myinput-number.ant-input-number .ant-input-number-input {
  text-align: right;
  padding-right: 28px;
}
.myform-section-title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #D22027;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 24px;
}
.ant-form-horizontal .myform-section-title {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.mobile .ant-form-horizontal .myform-section-title {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.myform-section-description {
  font-size: 0.6em;
  color: #8c8c8c;
  font-weight: 400;
}
span.myform-section-description {
  margin-left: 16px;
}
.myform-action-pane {
  background-color: #fefefe;
  border: 1px solid #14837B;
  border-radius: 4px;
  padding: 24px 32px;
  margin-bottom: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.myform-action-pane .search-more-link {
  -webkit-text-decoration: underline dashed;
          text-decoration: underline dashed;
}
.mobile .myform-action-pane,
.tablet .myform-action-pane {
  padding: 16px 24px;
}
.checklist-row {
  zoom: 1;
  line-height: 32px;
  padding: 0 6px;
}
.checklist-row::before,
.checklist-row::after {
  display: table;
  content: '';
}
.checklist-row::after {
  clear: both;
}
.checklist-row::before,
.checklist-row::after {
  display: table;
  content: '';
}
.checklist-row::after {
  clear: both;
}
.checklist-row::before,
.checklist-row::after {
  display: table;
  content: '';
}
.checklist-row::after {
  clear: both;
}
.checklist-row::before,
.checklist-row::after {
  display: table;
  content: '';
}
.checklist-row::after {
  clear: both;
}
.checklist-row.checklist-row-cols {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.checklist-row.checklist-row-cols::before,
.checklist-row.checklist-row-cols::after {
  display: none;
  content: unset;
}
.checklist-row.checklist-row-border {
  border-bottom: 1px dashed #d9d9d9;
}
.checklist-row.checklist-row-border:last-child {
  border-bottom: none;
}
.checklist-row.checklist-row-footer {
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #fafafa;
  border-bottom: 1px dashed #d9d9d9 !important;
}
.checklist-row.checklist-row-description {
  margin-bottom: 8px;
  color: #8c8c8c;
}
.checklist-row.checklist-row-description:hover,
.checklist-row.checklist-row-description:active {
  background-color: inherit;
}
.checklist-row.checklist-row-title {
  line-height: inherit;
  padding-top: 4px;
  padding-bottom: 4px;
  background: #e8e8e8;
}
.checklist-row.checklist-row-title label {
  color: #1890ff;
}
.checklist-row.checklist-row-title .title .topic_no {
  display: inline-block;
  background: #1890ff;
  color: #fff;
  width: 1.25em;
  line-height: 1.25em;
  text-align: center;
  border-radius: 50%;
  margin-right: 4px;
}
.checklist-row.checklist-row-title:hover,
.checklist-row.checklist-row-title:active {
  background: #e8e8e8;
}
.checklist-row > label {
  display: block;
  float: left;
}
.checklist-row > label.sub {
  padding-left: 24px;
}
.checklist-row .checklist-check {
  display: block;
  float: right;
  position: relative;
}
.checklist-row .checklist-check .ant-radio-wrapper,
.checklist-row .checklist-check .ant-checkbox-wrapper {
  margin-right: 0;
  margin-left: 0;
  width: 32px;
  text-align: center;
}
.checklist-row .checklist-check .check-clear-btn {
  position: absolute;
  right: -16px;
  font-size: 10px;
  top: 1px;
  display: none;
}
.checklist-row:hover,
.checklist-row:active {
  background: #fafafa;
}
.checklist-row:hover .checklist-check .check-clear-btn.check-clear-btn-active,
.checklist-row:active .checklist-check .check-clear-btn.check-clear-btn-active {
  display: block;
}
.myselect-dropdown-border li.ant-select-dropdown-menu-item {
  border-bottom: 1px dashed #8c8c8c;
}
.myselect-dropdown-border li.ant-select-dropdown-menu-item:last-child {
  border-bottom: none;
}
.myselect-dropdown-sm li.ant-select-dropdown-menu-item {
  font-size: 0.95em;
}
/*
 * My Card
 * 1. mycard - Normal and base
 * 2. mycard-with-details - Header + Details as list
 * 3. mycard-columns - Card with Left, Right column
 */
.mycard {
  position: relative;
}
.tablet .mycard .ant-card-body {
  padding: 12px;
}
.mycard .mycard-header {
  margin-bottom: 16px;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
}
.mycard .mycard-header .mycard-image {
  margin-bottom: 8px;
}
.mycard .mycard-header .title {
  font-size: 1.2em;
  font-weight: 600;
  color: #14837B;
}
.mycard .mycard-header .subtitle {
  color: #1890ff;
}
.mycard .mycard-button-action {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.mycard .mycard-tags {
  margin-top: 4px;
}
.mycard .mycard-tags .ant-tag {
  margin-right: 4px;
}
.mycard .mycard-invalid-status {
  position: absolute;
  bottom: 0px;
  left: 4px;
  font-size: 0.85em;
  color: #bfbfbf;
}
.mycard.ant-card {
  border-radius: 4px;
}
.mycard.mycard-sm .ant-card-body {
  padding: 16px 16px;
}
.mycard.mycard-sm .ant-card-body .mycard-button-action {
  top: 0;
  right: 0;
}
.mycard.mycard-sm .ant-card-body ul.mycard-details {
  font-size: 0.9em;
}
.mycard.mycard-sm .ant-card-body ul.mycard-details li {
  margin-bottom: 1px;
}
.mycard.mycard-inactive {
  background-image: repeating-linear-gradient(45deg, #f5f5f5, #f5f5f5 20px, transparent 20px, transparent 35px);
}
.mycard.mycard-disabled {
  background-color: #f5f5f5;
}
.mycard.mycard-selected {
  background-color: #d9f7be;
}
.mycard.ant-card-hoverable:hover {
  -webkit-box-shadow: 0 2px 8px rgba(20, 131, 123, 0.35);
          box-shadow: 0 2px 8px rgba(20, 131, 123, 0.35);
}
.mycard-with-details .mycard-header {
  text-align: center;
}
.mycard-with-details ul.mycard-details {
  list-style: none;
  padding: 0;
  margin: 8px 0 0;
}
.mycard-with-details ul.mycard-details > li {
  margin-bottom: 8px;
}
.mycard-with-details ul.mycard-details > li .title {
  color: #14837B;
  font-size: 1em;
}
.mycard-with-details ul.mycard-details > li .subdetail {
  margin-top: 2px;
  margin-bottom: 12px;
  font-size: 0.9em;
}
.mycard-with-details ul.mycard-details > li .label {
  color: #1890ff;
  display: inline-block;
  min-width: 120px;
}
.mycard-with-details ul.mycard-details > li .label::after {
  content: " : ";
}
.mycard-with-details ul.mycard-details > li .value {
  display: inline-block;
}
.mycard-with-details ul.mycard-details > li.separator {
  border: none;
  border-top: 1px solid #bfbfbf;
  margin: 16px 0;
}
.mycard-with-details ul.mycard-details > li:last-child {
  margin-bottom: 0;
}
.mycard-with-details ul.mycard-details > li .anticon {
  color: #14837B;
  padding-right: 4px;
}
.mycard-with-details ul.mycard-details > li a {
  -webkit-text-decoration: underline dashed;
          text-decoration: underline dashed;
}
.mycard-columns .mycard-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-line-pack: stretch;
      align-content: stretch;
}
.mycard-columns .mycard-header {
  margin-right: 16px;
  margin-bottom: 0;
  -ms-flex-item-align: center;
      align-self: center;
}
.mycard-columns .mycard-header .mycard-image {
  margin-bottom: 0;
}
.mycard-columns ul.mycard-details {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-top: 0;
}
.mycard-columns .mycard-side-actions {
  -ms-flex-item-align: center;
      align-self: center;
}
.mycard-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
}
.mycard-list .mycard {
  margin: 0 8px 8px 0;
  min-width: 250px;
}
@media (max-width: 575px) {
  .mycard-list .mycard {
    width: 100%;
    margin: 0 0 12px;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
}
.mycard-list .ant-empty {
  margin-left: auto;
  margin-right: auto;
}
.mycard-list.mycard-list-striped > *:nth-child(even) {
  background-color: #fafafa;
}
.mycard-list.mycard-list-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.mycard-list.mycard-list-vertical.mycard-list-line > * {
  border-bottom: 1px solid #d9d9d9;
}
.mycard-list.mycard-list-vertical.mycard-list-line > *:last-child {
  border-bottom: none;
}
.mycard-list.mycard-list-grid {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.mycard-list.mycard-list-grid .mycard {
  margin: 0;
  border-radius: 0;
  -ms-flex-preferred-size: 33.33%;
      flex-basis: 33.33%;
  min-width: unset;
}
.mycard-list.mycard-list-grid .mycard .ant-card-body,
.mycard-list.mycard-list-grid .mycard .mycard-content {
  height: 100%;
}
@media (min-width: 576px) and (max-width: 1599px) {
  .mycard-list.mycard-list-grid .mycard {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
  }
}
@media (max-width: 575px) {
  .mycard-list.mycard-list-grid .mycard {
    width: 100%;
    margin-bottom: 8px;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
}
.mycard-popover .ant-popover-inner-content {
  padding: 0;
}
.mycard-popover .ant-popover-inner-content .mycard {
  width: 300px;
}
.mycard-select-card {
  font-size: 0.95em;
  font-weight: normal !important;
}
.mycard-select-card label {
  font-weight: 500 !important;
  color: #14837B;
}
.mycard-select-card .card-title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1em;
  color: #14837B;
  font-weight: 500 !important;
}
.mycard-select-card .card-detail {
  font-size: 0.9em;
}
.mycard-select-card.with-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.mycard-select-card.with-image .col-image {
  margin-right: 6px;
}
.mycard-select-card.with-image .col-content {
  white-space: normal;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  line-height: 1.5;
}
.mycard-ant-list.ant-list {
  min-height: 166px;
}
.mycard-ant-list.ant-list > .ant-spin-nested-loading {
  min-height: 166px;
}
.mycard-ant-list.ant-list .ant-list-item {
  padding: 0;
}
.mycard-ant-list.ant-list .load-more-pane {
  text-align: center;
  margin: 12px 0;
}
.ant-tag.mytag-tiny {
  padding: 0 4px;
  font-size: 11px;
  line-height: 1.5;
  margin-right: 2px;
}
.mytags-list {
  display: inline-block;
}
.ant-tag.mytag-dash {
  border-style: dashed;
}
.ant-tag.mytag-primary {
  background-color: #14837B;
  border-color: #14837B;
  color: #fff;
}
.ant-tag.mytag-primary .anticon {
  color: #fff;
}
.ant-tag.mytag-secondary {
  background-color: #D22027;
  border-color: #D22027;
  color: #fff;
}
.ant-tag.mytag-secondary .anticon {
  color: #fff;
}
.ant-tag.mytag-info {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #fff;
}
.ant-tag.mytag-info .anticon {
  color: #fff;
}
.ant-tag.mytag-error {
  background-color: #f5222d;
  border-color: #f5222d;
  color: #fff;
}
.ant-tag.mytag-error .anticon {
  color: #fff;
}
.ant-tag.mytag-warning {
  background-color: #faad14;
  border-color: #faad14;
  color: #fff;
}
.ant-tag.mytag-warning .anticon {
  color: #fff;
}
.ant-tag.mytag-success {
  background-color: #52c41a;
  border-color: #52c41a;
  color: #fff;
}
.ant-tag.mytag-success .anticon {
  color: #fff;
}
.ant-tag.mytag-inactive {
  background-color: #8c8c8c;
  border-color: #8c8c8c;
  color: #fff;
}
.ant-tag.mytag-inactive .anticon {
  color: #fff;
}
.material-design-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-item-align: center;
      align-self: center;
  position: relative;
  height: 1em;
  width: 1em;
}
.material-design-icon > .material-design-icon__svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
  position: absolute;
  bottom: -0.125em;
}

.image-loader {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 96px;
  height: 96px;
  background: #e8e8e8;
}
.image-loader img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.image-loader > * {
  position: absolute !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
a .image-loader {
  border: 1px solid transparent;
}
a:focus .image-loader,
a:hover .image-loader {
  border: 1px dashed #14837B;
}
.image-loader-loading > .anticon {
  width: auto;
  height: auto;
  font-size: 32px;
}
.image-loader-placeholder > .anticon {
  width: auto;
  height: auto;
  color: #bfbfbf;
  font-size: 56px;
}
.image-circle,
.image-circle img {
  border-radius: 50%;
}
.image-round,
.image-round img {
  border-radius: 12px;
}
.image-size-very-tiny.image-round,
.image-size-very-tiny.image-round img {
  border-radius: 2px;
}
.image-size-tiny.image-round,
.image-size-tiny.image-round img {
  border-radius: 4px;
}
.image-size-small.image-round,
.image-size-small.image-round img {
  border-radius: 6px;
}
.image-size-large.image-round,
.image-size-large.image-round img {
  border-radius: 16px;
}
.image-uploader-container {
  display: inline-block;
  padding: 0;
  margin: 8px;
}
.image-uploader-container.has-delete {
  margin-left: 28px;
  margin-right: 28px;
}
.image-uploader-delete {
  position: absolute;
  top: 0;
  right: -24px;
}
.image-uploader .ant-upload.ant-upload-select-picture-card {
  width: auto;
  height: auto;
  margin-right: 0;
  margin-bottom: 0;
  border-radius: 0;
}
.image-uploader .ant-upload.ant-upload-select-picture-card:hover,
.image-uploader .ant-upload.ant-upload-select-picture-card:focus {
  border-color: #1890ff;
}
.image-uploader .ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 4px;
}
.image-uploader.image-circle .ant-upload.ant-upload-select-picture-card {
  border-radius: 50%;
}
.image-uploader.image-round .ant-upload.ant-upload-select-picture-card {
  border-radius: 12px;
}
.image-size-very-tiny.image-uploader.image-round .ant-upload.ant-upload-select-picture-card {
  border-radius: 2px;
}
.image-size-tiny.image-uploader.image-round .ant-upload.ant-upload-select-picture-card {
  border-radius: 4px;
}
.image-size-small.image-uploader.image-round .ant-upload.ant-upload-select-picture-card {
  border-radius: 6px;
}
.image-size-large.image-uploader.image-round .ant-upload.ant-upload-select-picture-card {
  border-radius: 16px;
}
.image-size-very-tiny {
  width: 22px;
  height: 22px;
}
.image-size-very-tiny .image-loader-loading > .anticon {
  font-size: 10px;
}
.image-size-very-tiny .image-loader-placeholder > .anticon {
  font-size: 10px;
}
.image-size-tiny {
  width: 32px;
  height: 32px;
}
.image-size-tiny .image-loader-loading > .anticon {
  font-size: 12px;
}
.image-size-tiny .image-loader-placeholder > .anticon {
  font-size: 18px;
}
.image-size-small {
  width: 48px;
  height: 48px;
}
.image-size-small .image-loader-loading > .anticon {
  font-size: 16px;
}
.image-size-small .image-loader-placeholder > .anticon {
  font-size: 32px;
}
.image-size-large {
  width: 200px;
  height: 200px;
}
.image-size-large .image-loader-loading > .anticon {
  font-size: 48px;
}
.image-size-large .image-loader-placeholder > .anticon {
  font-size: 100px;
}
.image-with-name {
  display: inline-block;
}
.image-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 96px;
  font-size: 28px;
  margin-left: 8px;
}
.image-text-very-tiny {
  display: inline-block;
  vertical-align: middle;
  line-height: 22px;
}
.image-text-tiny {
  display: inline-block;
  vertical-align: middle;
  line-height: 32px;
}
.image-text-small {
  display: inline-block;
  vertical-align: middle;
  line-height: 48px;
  margin-left: 4px;
  font-size: 14px;
}
.image-text-large {
  display: inline-block;
  vertical-align: middle;
  line-height: 200px;
  font-size: 64px;
  margin-left: 16px;
}
.myuploader-container .ant-upload-picture-card-wrapper {
  display: block;
}
.myuploader-container .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #14837B;
}
.myuploader-container .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #14837B;
}
.myuploader-container .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  -o-object-fit: contain;
     object-fit: contain;
}
.myuploader-container .ant-upload-list-picture-card .ant-upload-list-item {
  background-color: #fff;
}
.myuploader-container .ant-upload-list-picture-card .ant-upload-list-picture-card-container {
  margin: 0 4px 8px 0;
}
.vue-lb-container video {
  max-width: 100%;
  height: auto;
}
@media (min-width: 1200px) {
  .vue-lb-container video {
    max-width: 1024px;
  }
}
.vue-lb-container img.vue-lb-modal-image {
  background-color: white;
}
.vue-lb-container .vue-lb-info {
  position: static;
  height: auto;
  padding: 4px 8px;
  font-size: 0.9em;
  text-align: right;
}
.vue-lb-container .vue-lb-footer {
  position: static;
}
.vue-lb-container .vue-lb-modal-thumbnail {
  background-color: #fff;
}
.material-design-icon.anticon {
  width: 1em;
  height: 1em;
}
.material-design-icon.anticon > .material-design-icon__svg {
  bottom: 0;
}
.mytable td .mylayout-left-right .mylayout-left {
  width: 48px;
  min-width: 48px;
}
.mytable td .mylayout-left-right .mylayout-right {
  min-width: 150px;
}
.mytable td.mytable-action-col a {
  -webkit-text-decoration: underline dashed;
          text-decoration: underline dashed;
}
.mytable td .table-description {
  color: #8c8c8c;
  font-size: 0.95em;
}
.mytable td a:hover {
  -webkit-text-decoration: underline dashed;
          text-decoration: underline dashed;
}
.mytable .ant-tag {
  margin-right: 4px;
  margin-bottom: 4px;
}
.mytable .ant-table-body {
  margin: 0 !important;
  overflow-x: auto;
}
tbody td.column-detail {
  min-width: 200px;
}
.desktop tbody td.column-detail {
  width: 200px;
}
tbody td.column-row-no {
  width: 50px;
  font-size: 13px;
}
tbody td.column-checkbox {
  width: 50px;
  text-align: center;
  font-size: 13px;
}
.mytable.mytable-strip .ant-table-tbody > tr:nth-child(even) > td {
  background-color: #f5fcff;
}
.mytable .ant-table-tbody > tr.inactive > td {
  background-color: #f5f5f5;
  text-decoration: line-through;
}
.mytable .ant-table-tbody > tr.inactive > td.mytable-action-col {
  text-decoration: unset;
}
.mytable .ant-table-tbody > tr.inactive:hover > td {
  background-color: #dbdbdb !important;
}
.mytable .ant-table-tbody > tr.new > td {
  background-color: #eeffde;
}
.mytable .ant-table-tbody > tr.new:hover > td {
  background-color: #f6ffed !important;
}
.mytable .ant-table-tbody > tr.change > td {
  background-color: #fdffd7;
}
.mytable .ant-table-tbody > tr.change:hover > td {
  background-color: #feffe6 !important;
}
.mylayout-left-right,
.mylayout-right-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  position: relative;
  zoom: 1;
}
.mylayout-left-right::before,
.mylayout-right-left::before,
.mylayout-left-right::after,
.mylayout-right-left::after {
  display: table;
  content: '';
}
.mylayout-left-right::after,
.mylayout-right-left::after {
  clear: both;
}
.mylayout-left-right::before,
.mylayout-right-left::before,
.mylayout-left-right::after,
.mylayout-right-left::after {
  display: table;
  content: '';
}
.mylayout-left-right::after,
.mylayout-right-left::after {
  clear: both;
}
.mylayout-left-right::before,
.mylayout-right-left::before,
.mylayout-left-right::after,
.mylayout-right-left::after {
  display: table;
  content: '';
}
.mylayout-left-right::after,
.mylayout-right-left::after {
  clear: both;
}
.mylayout-left-right::before,
.mylayout-right-left::before,
.mylayout-left-right::after,
.mylayout-right-left::after {
  display: table;
  content: '';
}
.mylayout-left-right::after,
.mylayout-right-left::after {
  clear: both;
}
.mylayout-left-right .sticky,
.mylayout-right-left .sticky {
  position: sticky;
  top: 8px;
}
.mylayout-left-right .mylayout-left,
.mylayout-right-left .mylayout-left {
  margin-right: 12px;
  position: relative;
}
.mylayout-left-right .mylayout-right,
.mylayout-right-left .mylayout-right {
  position: relative;
}
@media (max-width: 1199px) {
  .mylayout-left-right.responsive,
  .mylayout-right-left.responsive {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .mylayout-left-right.responsive.reverse,
  .mylayout-right-left.responsive.reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .mylayout-left-right.responsive.reverse .mylayout-left,
  .mylayout-right-left.responsive.reverse .mylayout-left {
    margin-top: 24px;
  }
  .mylayout-left-right.responsive .mylayout-left,
  .mylayout-right-left.responsive .mylayout-left {
    margin-right: 0;
    margin-bottom: 24px;
  }
  .mylayout-left-right .mylayout-hidden-sm,
  .mylayout-right-left .mylayout-hidden-sm {
    display: none !important;
  }
  .mylayout-left-right .sticky,
  .mylayout-right-left .sticky {
    position: static;
  }
}
.mylayout-left-right .mylayout-left {
  width: 325px;
}
.mylayout-left-right .mylayout-left.small {
  width: 275px;
}
.mylayout-left-right .mylayout-right {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 540px;
}
.mylayout-left-right.responsive .mylayout-left {
  min-width: 325px;
}
.mylayout-left-right.responsive .mylayout-left.small {
  min-width: 275px;
}
@media (max-width: 1199px) {
  .mylayout-left-right.responsive .mylayout-left {
    width: 100%;
    min-width: unset;
  }
  .mylayout-left-right.responsive .mylayout-right {
    min-width: auto;
  }
}
.mylayout-right-left .mylayout-right {
  width: 325px;
}
.mylayout-right-left .mylayout-right.small {
  width: 275px;
}
.mylayout-right-left .mylayout-left {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 540px;
}
.mylayout-right-left.responsive .mylayout-right {
  min-width: 325px;
}
.mylayout-right-left.responsive .mylayout-right.small {
  min-width: 275px;
}
@media (max-width: 1199px) {
  .mylayout-right-left.responsive .mylayout-right {
    width: 100%;
    min-width: unset;
  }
  .mylayout-right-left.responsive .mylayout-left {
    min-width: auto;
  }
}
.row {
  zoom: 1;
  width: 100%;
}
.row::before,
.row::after {
  display: table;
  content: '';
}
.row::after {
  clear: both;
}
.row::before,
.row::after {
  display: table;
  content: '';
}
.row::after {
  clear: both;
}
.row::before,
.row::after {
  display: table;
  content: '';
}
.row::after {
  clear: both;
}
.row::before,
.row::after {
  display: table;
  content: '';
}
.row::after {
  clear: both;
}
.row .left {
  float: left;
}
.row .right {
  float: right;
}
body[data-device=mobile] .ant-modal-body {
  padding: 24px 16px 16px;
}
::-moz-selection {
  color: #fff;
  background: #1890ff;
}
::selection {
  color: #fff;
  background: #1890ff;
}
.ant-btn-info {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-btn-info > a:only-child {
  color: currentColor;
}
.ant-btn-info > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-info:hover,
.ant-btn-info:focus {
  color: #2c8f85;
  background-color: #1890ff;
  border-color: #2c8f85;
}
.ant-btn-info:hover > a:only-child,
.ant-btn-info:focus > a:only-child {
  color: currentColor;
}
.ant-btn-info:hover > a:only-child::after,
.ant-btn-info:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-info:active,
.ant-btn-info.active {
  color: #095c59;
  background-color: #1890ff;
  border-color: #095c59;
}
.ant-btn-info:active > a:only-child,
.ant-btn-info.active > a:only-child {
  color: currentColor;
}
.ant-btn-info:active > a:only-child::after,
.ant-btn-info.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-info-disabled,
.ant-btn-info.disabled,
.ant-btn-info[disabled],
.ant-btn-info-disabled:hover,
.ant-btn-info.disabled:hover,
.ant-btn-info[disabled]:hover,
.ant-btn-info-disabled:focus,
.ant-btn-info.disabled:focus,
.ant-btn-info[disabled]:focus,
.ant-btn-info-disabled:active,
.ant-btn-info.disabled:active,
.ant-btn-info[disabled]:active,
.ant-btn-info-disabled.active,
.ant-btn-info.disabled.active,
.ant-btn-info[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-btn-info-disabled > a:only-child,
.ant-btn-info.disabled > a:only-child,
.ant-btn-info[disabled] > a:only-child,
.ant-btn-info-disabled:hover > a:only-child,
.ant-btn-info.disabled:hover > a:only-child,
.ant-btn-info[disabled]:hover > a:only-child,
.ant-btn-info-disabled:focus > a:only-child,
.ant-btn-info.disabled:focus > a:only-child,
.ant-btn-info[disabled]:focus > a:only-child,
.ant-btn-info-disabled:active > a:only-child,
.ant-btn-info.disabled:active > a:only-child,
.ant-btn-info[disabled]:active > a:only-child,
.ant-btn-info-disabled.active > a:only-child,
.ant-btn-info.disabled.active > a:only-child,
.ant-btn-info[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-info-disabled > a:only-child::after,
.ant-btn-info.disabled > a:only-child::after,
.ant-btn-info[disabled] > a:only-child::after,
.ant-btn-info-disabled:hover > a:only-child::after,
.ant-btn-info.disabled:hover > a:only-child::after,
.ant-btn-info[disabled]:hover > a:only-child::after,
.ant-btn-info-disabled:focus > a:only-child::after,
.ant-btn-info.disabled:focus > a:only-child::after,
.ant-btn-info[disabled]:focus > a:only-child::after,
.ant-btn-info-disabled:active > a:only-child::after,
.ant-btn-info.disabled:active > a:only-child::after,
.ant-btn-info[disabled]:active > a:only-child::after,
.ant-btn-info-disabled.active > a:only-child::after,
.ant-btn-info.disabled.active > a:only-child::after,
.ant-btn-info[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-info:hover,
.ant-btn-info:focus {
  color: #fff;
  background-color: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn-info:hover > a:only-child,
.ant-btn-info:focus > a:only-child {
  color: currentColor;
}
.ant-btn-info:hover > a:only-child::after,
.ant-btn-info:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-info:active,
.ant-btn-info.active {
  color: #fff;
  background-color: #096dd9;
  border-color: #096dd9;
}
.ant-btn-info:active > a:only-child,
.ant-btn-info.active > a:only-child {
  color: currentColor;
}
.ant-btn-info:active > a:only-child::after,
.ant-btn-info.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.btn-danger-outline {
  color: #f5222d;
  background-color: #fff;
  border-color: #f5222d;
}
.btn-danger-outline > a:only-child {
  color: currentColor;
}
.btn-danger-outline > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.btn-danger-outline:hover,
.btn-danger-outline:focus {
  color: #2c8f85;
  background-color: #fff;
  border-color: #2c8f85;
}
.btn-danger-outline:hover > a:only-child,
.btn-danger-outline:focus > a:only-child {
  color: currentColor;
}
.btn-danger-outline:hover > a:only-child::after,
.btn-danger-outline:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.btn-danger-outline:active,
.btn-danger-outline.active {
  color: #095c59;
  background-color: #fff;
  border-color: #095c59;
}
.btn-danger-outline:active > a:only-child,
.btn-danger-outline.active > a:only-child {
  color: currentColor;
}
.btn-danger-outline:active > a:only-child::after,
.btn-danger-outline.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.btn-danger-outline-disabled,
.btn-danger-outline.disabled,
.btn-danger-outline[disabled],
.btn-danger-outline-disabled:hover,
.btn-danger-outline.disabled:hover,
.btn-danger-outline[disabled]:hover,
.btn-danger-outline-disabled:focus,
.btn-danger-outline.disabled:focus,
.btn-danger-outline[disabled]:focus,
.btn-danger-outline-disabled:active,
.btn-danger-outline.disabled:active,
.btn-danger-outline[disabled]:active,
.btn-danger-outline-disabled.active,
.btn-danger-outline.disabled.active,
.btn-danger-outline[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn-danger-outline-disabled > a:only-child,
.btn-danger-outline.disabled > a:only-child,
.btn-danger-outline[disabled] > a:only-child,
.btn-danger-outline-disabled:hover > a:only-child,
.btn-danger-outline.disabled:hover > a:only-child,
.btn-danger-outline[disabled]:hover > a:only-child,
.btn-danger-outline-disabled:focus > a:only-child,
.btn-danger-outline.disabled:focus > a:only-child,
.btn-danger-outline[disabled]:focus > a:only-child,
.btn-danger-outline-disabled:active > a:only-child,
.btn-danger-outline.disabled:active > a:only-child,
.btn-danger-outline[disabled]:active > a:only-child,
.btn-danger-outline-disabled.active > a:only-child,
.btn-danger-outline.disabled.active > a:only-child,
.btn-danger-outline[disabled].active > a:only-child {
  color: currentColor;
}
.btn-danger-outline-disabled > a:only-child::after,
.btn-danger-outline.disabled > a:only-child::after,
.btn-danger-outline[disabled] > a:only-child::after,
.btn-danger-outline-disabled:hover > a:only-child::after,
.btn-danger-outline.disabled:hover > a:only-child::after,
.btn-danger-outline[disabled]:hover > a:only-child::after,
.btn-danger-outline-disabled:focus > a:only-child::after,
.btn-danger-outline.disabled:focus > a:only-child::after,
.btn-danger-outline[disabled]:focus > a:only-child::after,
.btn-danger-outline-disabled:active > a:only-child::after,
.btn-danger-outline.disabled:active > a:only-child::after,
.btn-danger-outline[disabled]:active > a:only-child::after,
.btn-danger-outline-disabled.active > a:only-child::after,
.btn-danger-outline.disabled.active > a:only-child::after,
.btn-danger-outline[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.btn-danger-outline:hover,
.btn-danger-outline:focus {
  color: #c20912;
  background-color: #fff;
  border-color: #c20912;
}
.btn-danger-outline:hover > a:only-child,
.btn-danger-outline:focus > a:only-child {
  color: currentColor;
}
.btn-danger-outline:hover > a:only-child::after,
.btn-danger-outline:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.btn-danger-outline:active,
.btn-danger-outline.active {
  color: #c20912;
  background-color: #fff;
  border-color: #c20912;
}
.btn-danger-outline:active > a:only-child,
.btn-danger-outline.active > a:only-child {
  color: currentColor;
}
.btn-danger-outline:active > a:only-child::after,
.btn-danger-outline.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-card-head-title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
}
.anticon.empty::before {
  content: " ";
  width: 1em;
  height: 1em;
  display: inline-block;
}
.ant-list > .ant-spin-nested-loading {
  min-height: 80px;
}
.mobile .ant-btn-lg {
  height: 32px;
  padding: 0 10px;
  font-size: 14px;
}
.ant-select-dropdown-menu.ant-select-dropdown-menu-vertical {
  overflow-y: auto;
  scrollbar-width: 4px;
  scrollbar-width: thin;
  scrollbar-color: #14837B #F5F5F5;
}
.ant-select-dropdown-menu.ant-select-dropdown-menu-vertical::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}
.ant-select-dropdown-menu.ant-select-dropdown-menu-vertical::-webkit-scrollbar {
  background-color: #F5F5F5;
}
.ant-select-dropdown-menu.ant-select-dropdown-menu-vertical::-webkit-scrollbar:horizontal {
  height: 4px;
}
.ant-select-dropdown-menu.ant-select-dropdown-menu-vertical::-webkit-scrollbar:vertical {
  width: 4px;
}
.ant-select-dropdown-menu.ant-select-dropdown-menu-vertical::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #14837B;
}
.ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 6px 8px;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio:hover + .ant-radio-inner {
  -webkit-box-shadow: 0 1px 8px rgba(20, 131, 123, 0.8) !important;
          box-shadow: 0 1px 8px rgba(20, 131, 123, 0.8) !important;
}
.ant-upload.ant-upload-select-picture-card {
  border: none !important;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload:hover,
.ant-upload.ant-upload-select-picture-card > .ant-upload:focus {
  border-color: #14837B;
}
.tunjai-body {
  overflow-y: auto;
}
.tunjai-layout-header.ant-layout-header {
  height: 64px;
  padding: 0 12px 0 0;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
          box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: fixed;
  right: 0;
  z-index: 101;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  width: calc(100% - 250px);
}
.sidemenu-collapsed .tunjai-layout-header.ant-layout-header {
  width: calc(100% - 80px);
}
.mobile .tunjai-layout-header.ant-layout-header,
.tablet .tunjai-layout-header.ant-layout-header {
  padding: 0;
  width: 100%;
}
@media (max-width: 319px) {
  .tunjai-layout-header.ant-layout-header {
    position: relative;
  }
}
.tunjai-drawer-wrapper .ant-drawer-content,
.tunjai-sider-wrapper {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.65) !important;
  -webkit-box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
          box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.tunjai-drawer-wrapper .ant-drawer-body {
  padding: 0;
}
.tunjai-sider-wrapper .ant-layout-sider-children,
.tunjai-drawer-wrapper .ant-drawer-wrapper-body {
  overflow-y: auto;
  scrollbar-width: 4px;
  scrollbar-width: thin;
  scrollbar-color: #14837B #F5F5F5;
}
.tunjai-sider-wrapper .ant-layout-sider-children::-webkit-scrollbar-track,
.tunjai-drawer-wrapper .ant-drawer-wrapper-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}
.tunjai-sider-wrapper .ant-layout-sider-children::-webkit-scrollbar,
.tunjai-drawer-wrapper .ant-drawer-wrapper-body::-webkit-scrollbar {
  background-color: #F5F5F5;
}
.tunjai-sider-wrapper .ant-layout-sider-children::-webkit-scrollbar:horizontal,
.tunjai-drawer-wrapper .ant-drawer-wrapper-body::-webkit-scrollbar:horizontal {
  height: 4px;
}
.tunjai-sider-wrapper .ant-layout-sider-children::-webkit-scrollbar:vertical,
.tunjai-drawer-wrapper .ant-drawer-wrapper-body::-webkit-scrollbar:vertical {
  width: 4px;
}
.tunjai-sider-wrapper .ant-layout-sider-children::-webkit-scrollbar-thumb,
.tunjai-drawer-wrapper .ant-drawer-wrapper-body::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #14837B;
}
.tunjai-layout-content.ant-layout-content {
  margin-top: 64px;
  padding-bottom: 40px;
  overflow-y: auto;
  scrollbar-width: 10px;
  scrollbar-width: thin;
  scrollbar-color: #14837B #F5F5F5;
}
.tunjai-layout-content.ant-layout-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #F5F5F5;
}
.tunjai-layout-content.ant-layout-content::-webkit-scrollbar {
  background-color: #F5F5F5;
}
.tunjai-layout-content.ant-layout-content::-webkit-scrollbar:horizontal {
  height: 10px;
}
.tunjai-layout-content.ant-layout-content::-webkit-scrollbar:vertical {
  width: 10px;
}
.tunjai-layout-content.ant-layout-content::-webkit-scrollbar-thumb {
  background-color: #14837B;
  border: 2px solid #14837B;
}
.tunjai-layout-content.ant-layout-content .ant-back-top {
  bottom: 24px;
  right: 48px;
}
@media (max-width: 319px) {
  .tunjai-layout-content.ant-layout-content {
    padding-top: 0;
  }
}
.tunjai-layout {
  height: 100%;
}
.tunjai-layout .header-menu {
  float: right;
  height: 100%;
}
.tunjai-layout .header-menu .header-menu-icon {
  display: inline-block;
  vertical-align: top;
}
.tunjai-layout .header-menu .header-menu-user {
  display: inline-block;
  vertical-align: top;
}
.tunjai-layout .header-btn {
  color: rgba(0, 0, 0, 0.65);
  display: inline-block;
  font-size: 1.4em;
  line-height: 64px;
  height: 64px;
  padding: 0 24px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.tunjai-layout .header-btn:hover {
  background-color: #f5f5f5;
}
.tunjai-layout .page-fit-mobile,
.tunjai-layout .page-fit-tablet {
  width: calc(100vw - 250px - 10px);
}
.tunjai-layout.sidemenu-collapsed .page-fit-mobile,
.tunjai-layout.sidemenu-collapsed .page-fit-tablet {
  width: calc(100vw - 80px - 10px);
}
.mobile .page-fit-mobile,
.mobile .page-fit-tablet,
.tablet .page-fit-mobile,
.tablet .page-fit-tablet {
  width: calc(100vw - 10px);
  margin: 0 auto;
}
.mobile .page-fit-mobile.page-padding,
.mobile .page-fit-tablet.page-padding,
.tablet .page-fit-mobile.page-padding,
.tablet .page-fit-tablet.page-padding {
  width: calc(100vw - 10px);
}
.page-fit-mobile .mytable-scroll table,
.page-fit-tablet .mytable-scroll table {
  min-width: 850px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.page-header[data-v-60252891] {
  background: white;
  padding: 16px 32px;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.mobile .page-header[data-v-60252891] {
  padding: 12px 16px;
}
.page-title[data-v-60252891] {
  font-size: 2em;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: #14837B;
}
.mobile .page-title[data-v-60252891] {
  font-size: 1.4em;
}
.page-description[data-v-60252891] {
  margin-top: 4px;
  font-size: 0.9em;
  color: #8c8c8c;
}
.page-content[data-v-60252891] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.page-actions[data-v-60252891] {
  margin-left: 8px;
}
.mobile .page-actions[data-v-60252891] {
  margin-bottom: 8px;
  text-align: right;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}


/*# sourceMappingURL=app.91e23563.css.map*/