/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.page-header {
  background: white;
  padding: 16px 32px;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.mobile .page-header {
  padding: 12px 16px;
}
.page-title {
  font-size: 2em;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: #14837B;
}
.mobile .page-title {
  font-size: 1.4em;
}
.page-description {
  margin-top: 4px;
  font-size: 0.9em;
  color: #8c8c8c;
}
.page-content {
  flex-grow: 1;
}
.page-actions {
  margin-left: 8px;
}
.mobile .page-actions {
  margin-bottom: 8px;
  text-align: right;
}
