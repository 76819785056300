.tunjai-body {
	overflow-y: auto;
}
.tunjai-layout-header.ant-layout-header {
	height: @layout-header-height;
	padding: 0 12px 0 0;
	background: @white;
	box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
	position: fixed;
	right : 0;
	z-index: @zindex-layout-header;

	transition: all 0.2s;
	width: calc(100% - @layout-sidemenu-width);

	.sidemenu-collapsed & {
		width: calc(100% - @layout-sidemenu-collapsed-width);
	}

	.mobile & , .tablet & {
		padding : 0;
		width : 100%;
	}

	@media(max-width : @body-min-width - 1px) {
		& {
			position: relative;
		}
	}
}
.tunjai-drawer-wrapper .ant-drawer-content,
.tunjai-sider-wrapper  {
	background-color :@white !important;
	color : @text-color !important;
	box-shadow : @shadow-1-right;
}
.tunjai-drawer-wrapper .ant-drawer-body {
	padding : 0;
}
.tunjai-sider-wrapper .ant-layout-sider-children , .tunjai-drawer-wrapper .ant-drawer-wrapper-body {
	.custom_scrollbar();
}



.tunjai-layout-content.ant-layout-content {
	margin-top: @layout-header-height;
	padding-bottom : 40px;
	overflow-y: auto;
	.custom_scrollbar_layout();
	.ant-back-top {
		bottom : 24px;
		right : 48px;
	}

	@media(max-width : @body-min-width - 1px) {
		& {
			padding-top: 0;
		}
	}
}



.tunjai-layout {
	height : 100%;

	.header-menu {
		float : right;
		height: 100%;


		.header-menu-icon {
			display : inline-block;
			vertical-align: top;
		}
		.header-menu-user {
			display : inline-block;
			vertical-align: top;
		}

	}

	.header-btn {
		color : @text-color;
		display : inline-block;
		font-size : 1.4em;
		line-height: @layout-header-height;
		height : @layout-header-height;
		padding : 0 24px;
		transition: color 0.3s;
		user-select: none;
    &:hover {
      background-color: @white-hover-bg;
    }
	}
}


.page-fit-mobile, .page-fit-tablet {
	.tunjai-layout & {
		width : calc(100vw - @layout-sidemenu-width - @custom-scroll-width-layout);
	}
	.tunjai-layout.sidemenu-collapsed & {
		width : calc(100vw - @layout-sidemenu-collapsed-width - @custom-scroll-width-layout);
	}

	.mobile &, .tablet & {
		width : calc(100vw - @custom-scroll-width-layout);
		margin : 0 auto;
		&.page-padding {
			width : calc(100vw - @custom-scroll-width-layout);
		}
	}

	.mytable-scroll table {
		min-width : @layout-content-min-width;
	}
}
