@import '~ant-design-vue/lib/button/style/mixin.less';

body[data-device=mobile] {
	.ant-modal-body {
		padding : 24px 16px 16px;
	}
}

::selection {
  color: @white;
  background: @info-color;
}


.@{ant-prefix}-btn-info {
	.button-variant-other(@white,@info-color,@info-color);
	&:hover,
  &:focus {
    .button-color(
      @white;~`colorPalette('@{info-color}', 5) `; ~`colorPalette('@{info-color}', 5) `
    );
  }
  &:active,
  &.active {
    .button-color(
			@white;~`colorPalette('@{info-color}', 7) `; ~`colorPalette('@{info-color}', 7) `
    );
  }
}
.btn-danger-outline {
	.button-variant-other(@error-color,@white,@error-color);
	&:hover,
  &:focus {
    .button-color(
      darken(@error-color,15%);@white; darken(@error-color,15%)
    );
  }
  &:active,
  &.active {
    .button-color(
			darken(@error-color,15%);@white; darken(@error-color,15%)
    );
  }
}

.@{ant-prefix}-card-head-title {
  font-family: @font-family-title;
}

.anticon.empty::before {
  content: " ";
  width : 1em;
  height : 1em;
  display : inline-block;
}

.ant-list > .ant-spin-nested-loading {
  min-height : 80px;
}
.mobile .ant-btn-lg {
  height: 32px;
  padding: 0 10px;
  font-size: 14px;
}
.ant-select-dropdown-menu.ant-select-dropdown-menu-vertical {
  .custom_scrollbar();
}

.ant-descriptions {
  &.ant-descriptions-bordered.ant-descriptions-small {
    .ant-descriptions-item-label , .ant-descriptions-item-content {
      padding : 6px 8px;
    }
  }
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner ,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner ,
.ant-radio:hover + .ant-radio-inner {
  box-shadow : 0 1px 8px fade(@primary-color,80%) !important;
}
.ant-upload.ant-upload-select-picture-card {
  border : none !important;
  > .ant-upload {
    border : 1px dashed @border-color-base;
    border-radius : @border-radius-base;
    transition: border-color 0.3s ease;
    &:hover, &:focus {
      border-color : @primary-color;
    }
  }
}
