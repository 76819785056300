@import '~ant-design-vue/lib/style/mixins/index.less';
@import '~ant-design-vue/lib/style/core/base.less';
@import (css) url('https://fonts.googleapis.com/css?family=Prompt');

html, body {
	margin : 0;
	padding : 0;
	scroll-behavior: smooth;

}
body {
	font-family: @font-family;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: @body-background;
	color: @text-color;
	min-width: @body-min-width;
}
hr {
	border:none;
	border-top :1px solid @gray-6;
}


.application-container {
	height : 100%;
	width : 100%;
	min-height: 100%;
}

.border-primary {
	border: 1px solid @primary-color;
	border-radius: @border-radius-base;
	box-shadow: @shadow-1-right;
}

/* Change the white to any color ;) */
input:-webkit-autofill {
	&,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active  {
  	-webkit-box-shadow: 0 0 0 30px white inset !important;
	}
}

ul,ol {
	padding-left : 0;
}

.card-image-with-text-box {
	.card-image {
		float : left;
		margin-right : 8px;
	}
	.card-content {
		.card-title {
			font-weight : 600;
			margin-bottom : 4px;

			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.card-description {
			font-size : 0.9em;
		}
	}
}

#nprogress {
	.bar {
		background: @primary-color;
		height: 4px;
	}
	.peg {
		box-shadow: 0 0 10px @primary-color, 0 0 5px @primary-color;
	}
}

.page {
	min-height : 300px;
}
.page-action-right {
	text-align : right;
	margin-bottom : 8px;
}
.next-button {
	margin: 10px 0;
}
.page-padding {
	padding : 16px 16px 50px;
	.mobile & , .tablet & {
		padding : 8px 8px 24px;
	}
}
.page-padding-excluded {
	margin : -16px -16px -32px;
	.mobile & , .tablet & {
		margin : -8px -8px -24px;
	}
}




.mybreadcrumb {
	padding : 16px 16px 8px;

	.mobile & , .tablet & {
		padding : 16px 8px 8px;

		.mybreadcrumb-item-ellipsis {
			display : inline-block;
			vertical-align: bottom;
			max-width: 100px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}


}
.btn-page-action {
	min-width : 200px;
	margin-bottom : 16px;
	.mobile & {
		min-width : 150px;
	}
}
.btn-submit {
	min-width : 200px;
	.mobile &{
		min-width : 100px;
	}
}
.valign-top {
	vertical-align : top;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.text-bold {
	font-weight: 600;
}
.text-info {
	.text-emphasis-variant(@info-color);
}
.text-success {
	.text-emphasis-variant(@success-color);
}
.text-warning {
	.text-emphasis-variant(@warning-color);
}
.text-error {
	.text-emphasis-variant(@error-color);
}
.text-muted {
	.text-emphasis-variant(@text-muted);
}
.text-secondary {
	.text-emphasis-variant(@secondary-color);
}
.text-title,.text-primary {
	.text-emphasis-variant(@primary-color);
}
.text-value {
	color : @primary-color;
	text-decoration: underline dashed;
}
.hidden {
	display : none;
}
.multiline {
	white-space: pre-wrap;
    word-break: break-word;
}
.page-section-title {
	.section-title();
	margin-bottom : 24px;
	.page-section-action {
		float : right;
		font-family: @font-family;
	}
}
.page-section-description {
	font-size : 0.6em;
	color : @text-muted;
	font-weight: 400;
	span& {
		margin-left : 16px;
	}
}


@content-detail-label-width : 185px;
@content-detail-label-width-lg : 250px;
.content-with-details {
	margin-bottom : 24px;
	&:last-child {
		margin-bottom : 0;
	}
	.content-header {
		.section-title();
		margin-bottom : 4px;
	}
	.content-row {
		.clearfix();
		margin-bottom : 8px;
		padding : 0 8x;

		.content-label {
			color : @info-color;
			width : @content-detail-label-width;
			margin-right : 8px;
			text-align : right;
			float : left;
			&::after {
				content : " : ";
			}
			@media(min-width: @screen-lg-min) {
				width : @content-detail-label-width-lg;
			}
		}
		.content-value {
			float : left;
			width : 500px;
			width : calc(100% - @content-detail-label-width - 8px);

			@media(min-width: @screen-lg-min) {
				width : calc(100% - @content-detail-label-width-lg - 8px);
			}

			&.no-label {
				margin-left : @content-detail-label-width + 8px;
				@media(min-width: @screen-lg-min) {
					margin-left : @content-detail-label-width-lg + 8px;
				}
			}
		}

		.anticon {
			color: @primary-color;
			padding-right : 4px;
		}
		a {
			text-decoration: underline dashed;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
	.separator {
		border:none;
		border-top :1px solid @gray-6;
		margin : 16px 0;
	}
}

.cursor-pointer {
	cursor : pointer;
}

ul.no-style {
	list-style: none;
	padding : 0;
	margin : 0;
	> li {
		margin-bottom : 4px;
		&:last-child {
			margin-bottom : 0;
		}
	}
}

.mysteps-actions {
	margin : 24px 0 16px;
	text-align : right;
	.ant-btn {
		min-width : 200px;
		body[data-device=mobile] &{
			min-width: 120px;
		}
	}
}
.mytooltip {
	min-width : 100px;
	max-width : 300px;
}
.mytooltip-trigger , .mypopover-trigger {
	cursor: pointer;
	user-select: none;
}
.mytooltip-white {
	.ant-tooltip-arrow::before {
		background-color : @white;
	}
	.ant-tooltip-inner {
		background-color : @white;
		color : #616161;
	}
}
.mytooltip-warning {
	.ant-tooltip-arrow::before {
		background-color : @warning-color;
	}
	.ant-tooltip-inner {
		background-color : @warning-color;
		color : @white;
	}
}
.mytooltip-error {
	.ant-tooltip-arrow::before {
		background-color : @error-color;
	}
	.ant-tooltip-inner {
		background-color : @error-color;
		color : @white;
	}
}

.center-block {
	margin-left: auto;
	margin-right : auto;
}
.ql-toolbar {
	line-height: 1.15;
}

.mytab-menu {
	.mobile & .ant-menu-item .anticon {
		display : none;
	}
}

.mytab-content {
	background : @white;
	min-height: 150px;
	.clearfix();
}
.mytab-section-title {
	color : @secondary-color;
	font-family: @font-family-title;
	font-size : 1.2em;
	margin-bottom: 12px;
	.mytab-section-description {
		font-size : 0.8em;
		margin-top : 4px;
		color : @text-muted;
		font-family: @font-family;
		margin-left : 16px;
	}
}


.mybutton-group-vertical {
	> button {
		width : 100%;
		border-radius: 0;
		padding-top : 8px;
		padding-bottom : 9px;
		height : auto;
		margin-bottom : -1px;
		&:first-child {
			border-top-left-radius: @border-radius-base;
			border-top-right-radius: @border-radius-base;
		}
		&:last-child {
			border-bottom-left-radius: @border-radius-base;
			border-bottom-right-radius: @border-radius-base;
			margin-bottom : 0;
		}
		&:active, &:hover, &:focus {
			z-index : 1;
		}
	}

}
