.mytable {
	td {
		.mylayout-left-right {
			.mylayout-left {
				width : 48px;
				min-width: 48px;
			}
			.mylayout-right {
				min-width: 150px;;
			}
		}
		&.mytable-action-col a {
			text-decoration: underline dashed;
		}

		.table-description {
			color : @text-muted;
			font-size : 0.95em;
		}
		a:hover {
			text-decoration: underline dashed;
		}
	}

	.ant-tag {
		margin-right : 4px;
		margin-bottom : 4px;
	}
	.ant-table-body {
		margin : 0 !important;
		overflow-x : auto;
	}
}

tbody td {
	&.column-detail {
		min-width : 200px;
		.desktop & {
			width : 200px;
		}
	}
	&.column-row-no {
		width : 50px;
		font-size : 13px;
	}
	&.column-checkbox {
		width : 50px;
		text-align: center;
		font-size : 13px;
	}
}

.mytable.mytable-strip .ant-table-tbody > tr:nth-child(even) {
	> td {
		background-color : lighten(@blue-1,3%);
	}
}

.mytable .ant-table-tbody > tr {
	&.inactive {
		> td {
			background-color : @gray-3;
			text-decoration: line-through;
			&.mytable-action-col {
				text-decoration: unset;
			}
		}
		&:hover > td {
			background-color : darken(@gray-4,5%) !important;
		}
	}

	&.new {
		> td {
			background-color : darken(@green-1,3%);
		}
		&:hover > td {
			background-color : @green-1 !important;
		}
	}

	&.change {
		> td {
			background-color : darken(@yellow-1,3%);
		}
		&:hover > td {
			background-color : @yellow-1 !important;
		}
	}
}
