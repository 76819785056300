.mytag(@name;@bg-color;@color : @white) {
    .ant-tag.mytag-@{name} {    
        background-color : @bg-color;
        border-color : @bg-color;
        color : @color;
        .anticon {
            color : @color;
        }
    }
}
.ant-tag.mytag-tiny {
	padding : 0 4px;
	font-size : 11px;
    line-height: 1.5;
    margin-right : 2px;
}
.mytags-list {
	display : inline-block;
}
.ant-tag.mytag-dash {
    border-style : dashed,
}
.mytag(primary,@primary-color);
.mytag(secondary,@secondary-color);
.mytag(info,@info-color);
.mytag(error,@error-color);
.mytag(warning,@warning-color);
.mytag(success,@success-color);
.mytag(inactive,@gray-7);

