@import (css,inline) '~vue-material-design-icons/styles.css';

// Mixins
.image-circle-mixins() {
  border-radius: 50%;
}
.image-round-mixins() {
  border-radius : 12px;

  .image-size-very-tiny& {
    border-radius : 2px;
  }
  .image-size-tiny& {
    border-radius : 4px;
  }
  .image-size-small& {
    border-radius : 6px;
  }
  .image-size-large& {
    border-radius : 16px;
  }
}

//Image Loader
.image-loader {
  position: relative;
  display : inline-block;
  vertical-align: top;
  width : 96px;
  height : 96px;
  background : @gray-4;

  img {
    width : 100%;
		height : 100%;
		object-fit : contain;
  }

  > * {
    position : absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  a & {
    border : 1px solid transparent;
  }
  a:focus & ,a:hover & {
    border : 1px dashed @primary-color;
  }

}
.image-loader-loading > .anticon {
  width : auto;
  height : auto;
  font-size: 32px;
}
.image-loader-placeholder > .anticon {
  width : auto;
  height : auto;
  color : @gray-6;
  font-size: 56px;
}
.image-circle {
  & , img {
    .image-circle-mixins();
  }
}
.image-round {
  &, img {
    .image-round-mixins();
  }
}

// Image Uploader
.image-uploader-container {
	display : inline-block;
  padding : 0;
  margin : 8px;
	&.has-delete {
		margin-left: 28px;
		margin-right: 28px;
	}
}
.image-uploader-delete {
	position : absolute;
	top : 0;
	right : -24px;
}
.image-uploader {
	.ant-upload.ant-upload-select-picture-card {
		width : auto;
		height : auto;
		margin-right : 0;
		margin-bottom : 0;
		border-radius : 0;

		&:hover, &:focus {
			border-color : @highlight-color;
		}
		> .ant-upload {
			padding : 4px;
		}
	}

	&.image-circle .ant-upload.ant-upload-select-picture-card{
		.image-circle-mixins();
	}
	&.image-round .ant-upload.ant-upload-select-picture-card{
		.image-round-mixins();
	}
}



//Image Size

.image-size-very-tiny {
  width : 22px;
  height : 22px;
  .image-loader-loading > .anticon {
    font-size : 10px;
  }
  .image-loader-placeholder > .anticon {
    font-size : 10px;
  }
}
.image-size-tiny {
  width : 32px;
  height : 32px;

  .image-loader-loading > .anticon {
    font-size : 12px;
  }
  .image-loader-placeholder > .anticon {
    font-size : 18px;
  }
}

.image-size-small {
  width : 48px;
  height : 48px;
  .image-loader-loading > .anticon {
    font-size : 16px;
  }
  .image-loader-placeholder > .anticon {
    font-size : 32px;
  }
}
.image-size-large {
  width : 200px;
  height : 200px;
  .image-loader-loading > .anticon {
    font-size : 48px;
  }
  .image-loader-placeholder > .anticon {
    font-size : 100px;
  }
}

//Image with name
.image-with-name {
  display : inline-block;
}
.image-text-variant(@height) {
  display : inline-block;
  vertical-align : middle;
  line-height : @height;
}
.image-text {
  .image-text-variant(96px);
  font-size : 28px;
  margin-left : 8px;
}
.image-text-very-tiny {
  .image-text-variant(22px);
}
.image-text-tiny {
  .image-text-variant(32px);
}
.image-text-small {
  .image-text-variant(48px);
  margin-left : 4px;
  font-size : 14px;
}
.image-text-large {
  .image-text-variant(200px);
  font-size : 64px;
  margin-left : 16px;
}


//Gallery Uploader
.myuploader-container {
  .ant-upload-picture-card-wrapper {
    display : block;
  }
  .ant-upload-select-picture-card {
    i {
      font-size: 32px;
      color: @primary-color;
    }
    .ant-upload-text {
      margin-top: 8px;
      color: @primary-color;
    }
  }
  .ant-upload-list-picture-card {
    .ant-upload-list-item-thumbnail img {
      object-fit : contain;
    }
    .ant-upload-list-item {
      background-color: @white;
    }
    .ant-upload-list-picture-card-container {
      margin : 0 4px 8px 0;
    }
  }
}

//Lightbox

.vue-lb-container {
	video {
		max-width: 100%;
		height: auto;
		@media(min-width:@screen-xl) {
			max-width : 1024px;
		}
  }
  img.vue-lb-modal-image {
    background-color : white;
  }
  .vue-lb-info {
    position : static;
    height : auto;
    padding : 4px 8px;
    font-size : 0.9em;
    text-align : right;
  }
  .vue-lb-footer {
    position : static;
  }
  .vue-lb-modal-thumbnail {
    background-color : @white;
  }
}

.material-design-icon.anticon {
  width : 1em;
  height: 1em;
  > .material-design-icon__svg {
    bottom : 0;
  }
}
