@myform-input-max-width : 450px;
.ant-form {
	input.ant-input ,.ant-select , textarea.ant-input {
		max-width: @myform-input-max-width;
	}
	.ant-input-affix-wrapper {
		max-width: @myform-input-max-width;
		input.ant-input {
			width : 100%;
		}
	}

	hr {
		border-top : 1px solid @input-border-color;
		margin : 0 auto @form-item-margin-bottom;
		width : 80%;

		.mobile & {
			width : 100%;
		}
	}
	&.ant-form-vertical {
		hr {
			width : 100%;
		}
		.ant-form-item.display-only {
			margin-bottom : 8px;
		}
	}
	.myform-expandable-pane {
		padding-top : 8px;
	}
	@media(max-width:@screen-mobile) {
		&.ant-form-inline {
			.ant-form-item {
				margin-bottom : 12px;
			}
			.ant-row.myform-expandable-row {
				display : block;
				margin-bottom : 0;
			}
			.myform-expandable-pane {
				padding : 12px 16px;
				border : 1px dashed @info-color;
				border-radius : @border-radius-base;
			}
		}
	}

}
@myform-label-width : 175px;
@myform-label-width-lg : 225px;

@myform-label-width-modal : 150px;
.myform-label-col {
	width : @myform-label-width;
	float : left;
	@media(min-width: @screen-notebook) {
		width : @myform-label-width-lg;
	}
	@media(max-width: @screen-mobile) {
		width : 100%;
		float : none;
	}
	.ant-modal-body & {
		width : @myform-label-width-modal;
		@media(max-width: @screen-mobile) {
			width : 100%;
		}
	}
}
.myform-wrapper-col {
	width : 500px;
	width : calc(100% - @myform-label-width);
	float : left;
	@media(min-width: @screen-notebook) {
		width : calc(100% - @myform-label-width-lg);
	}
	@media(max-width: @screen-mobile) {
		width : 100%;
		float : none;
	}
	.ant-modal-body & {
		width : calc(100% - @myform-label-width-modal);
		@media(max-width: @screen-mobile) {
			width : 100%;
		}
	}
}

.myform-wrapper-offset-col {
	margin-left : @myform-label-width;
	@media(max-width: @screen-mobile) {
		margin-left : 0;
	}
	@media(min-width: @screen-notebook) {
		margin-left : @myform-label-width-lg;
	}
	.ant-modal-body & {
		margin-left : @myform-label-width-modal;
		@media(max-width: @screen-mobile) {
			margin-left : 0;
		}
	}
}

.myinput-number.ant-input-number {
	width : 125px;
	.ant-input-number-handler-wrap {
		opacity: 1;
		&:hover .ant-input-number-handler {
			height : 50%;
		}
		.ant-input-number-handler-up:hover ,
		.ant-input-number-handler-down:hover  {
			height: 50% !important;
		}
	}
	.ant-input-number-input {
		text-align: right;
		padding-right: 28px;
	}
}

.myform-section-title {
	.section-title();
	margin-bottom : 24px;

	.ant-form-horizontal & {
		width : 80%;
		margin-left: auto;
		margin-right : auto;

		.mobile & {
			width : 100%;
			margin-left : 0;
			margin-right : 0;
		}
	}
}
.myform-section-description {
	font-size : 0.6em;
	color : @text-muted;
	font-weight: 400;
	span& {
		margin-left : 16px;
	}
}

.myform-action-pane  {
	background-color : #fefefe;
	border : 1px solid @primary-color;
	border-radius: @border-radius-base;
	padding : 24px 32px;
	margin-bottom : 16px;
	user-select: none;

	.search-more-link {
		text-decoration: underline dashed;
	}

	.mobile &, .tablet & {
		padding : 16px 24px;
	}
}


.checklist-row {
	.clearfix();
	line-height: 32px;
	padding : 0 6px;

	&.checklist-row-cols {
		.noclearfix();
		display : flex;
		justify-content: space-between;
	}
	&.checklist-row-border {
		border-bottom : 1px dashed @gray-5;
		&:last-child {
			border-bottom : none;
		}
	}
	&.checklist-row-footer {
		padding-top : 4px;
		padding-bottom : 4px;
		background-color : @gray-2;
		border-bottom : 1px dashed @gray-5 !important;
	}
	&.checklist-row-description {
		margin-bottom : 8px;
		color : @text-muted;
		&:hover,&:active {
			background-color: inherit;
		}
	}
	&.checklist-row-title {
		line-height: inherit;
		padding-top : 4px;
		padding-bottom : 4px;
		background : @gray-4;
		label {
			color : @info-color;
		}
		.title .topic_no {
			display : inline-block;
			background:@info-color;
			color: @white;
			width: 1.25em;
			line-height: 1.25em;
			text-align: center;
			border-radius: 50%;
			margin-right : 4px;
		}
		&:hover,&:active {
			background: @gray-4;
		}
	}

	> label {
		display : block;
		float : left;
		&.sub {
			padding-left : 24px;
		}
	}
	.checklist-check {
		display : block;
		float : right;
		position: relative;
		.ant-radio-wrapper , .ant-checkbox-wrapper{
			margin-right : 0;
			margin-left : 0;
			width : 32px;
			text-align :center;
		}
		.check-clear-btn {
			position : absolute;
			right: -16px;
			font-size: 10px;
			top: 1px;
			display : none;
		}
	}

	&:hover, &:active {
		background: @gray-2;

		.checklist-check {
			.check-clear-btn.check-clear-btn-active {
				display: block;
			}
		}
	}
}
.myselect-dropdown-border {
	li.ant-select-dropdown-menu-item {
		border-bottom : 1px dashed @text-muted;
		&:last-child {
			border-bottom : none;
		}
	}
}
.myselect-dropdown-sm {
	li.ant-select-dropdown-menu-item {
		font-size : 0.95em;
	}

}
