/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.container {
  position: relative;
  padding: 100px 0px 144px;
  min-height: 100%;
  text-align: center;
  background-image: url("~@assets/images/bg_login.png");
  background-repeat: no-repeat;
  background-position: right bottom;
}
.container .main {
  padding: 0 16px;
  height: 100%;
}
.container.mobile {
  padding-top: 24px;
  background-size: contain;
}
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px 16px 16px;
  background-color: #033636;
  color: #fff;
  border-top: solid 1px #d9d9d9;
}
#tunjai_content {
  position: relative;
  width: fit-content;
  min-width: 100%;
}
.privacy-policy {
  font-size: 1.1em;
  color: #FFF;
  text-decoration: underline;
}
.mobile .privacy-policy {
  font-size: 1em;
}
a:hover {
  color: #0f85ff;
}
