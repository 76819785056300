.custom_scrollbar() {
	overflow-y: auto;
	scrollbar-width: @custom-scroll-width;
	scrollbar-width: thin;
	scrollbar-color: @primary-color #F5F5F5 ;

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	 	background-color: #F5F5F5;
	}
	&::-webkit-scrollbar {
		background-color: #F5F5F5;
	}

	&::-webkit-scrollbar:horizontal {
		height : @custom-scroll-width;
	}
	&::-webkit-scrollbar:vertical {
		width : @custom-scroll-width;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #000000;
		border: 2px solid @primary-color;
	}
}
.custom_scrollbar_layout() {
	overflow-y: auto;

	scrollbar-width: @custom-scroll-width-layout;
	scrollbar-width: thin;
	scrollbar-color: @primary-color #F5F5F5 ;

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
	 	background-color: #F5F5F5;
	}
	&::-webkit-scrollbar {
		background-color: #F5F5F5;
	}

	&::-webkit-scrollbar:horizontal {
		height : @custom-scroll-width-layout;
	}
	&::-webkit-scrollbar:vertical {
		width : @custom-scroll-width-layout;
	}

	&::-webkit-scrollbar-thumb {
		background-color: @primary-color;
		border: 2px solid @primary-color;
	}
}
.text-emphasis-variant(@color) {
	color: @color !important;
	a&:hover {
	  color: darken(@color, 10%) !important;
	}
  }

.section-title() {
	font-family: @font-family-title;
	color : @secondary-color;
	font-size : 1.5em;
	font-weight: 600;
}

.material-icon-size(@size) {
	width : @size;
	height : @size;
	vertical-align: top;
	> .material-design-icon__svg {
		width : @size;
		height : @size;
	}
}
.clearfix() {
	zoom: 1;
	&::before,
	&::after {
		display: table;
		content: '';
	}
	&::after {
		clear: both;
	}
}
.noclearfix() {
	&::before,
	&::after {
		display: none;
		content : unset;
	}
}

.reset-min-width-mobile() {
	@media(max-width:@screen-mobile) {
		min-width : unset;
	}
}
