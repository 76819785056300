/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.error-container {
  margin: 0 16px;
}
.error-detail {
  text-align: left;
  font-size: 13px;
  color: #8c8c8c;
  margin: 32px 24px 32px;
  padding: 8px 16px;
  border: 1px dashed #8c8c8c;
  border-radius: 4px;
}
.error-location {
  font-size: 14px;
  margin-top: 32px;
}
.error-action {
  margin-top: 16px;
}
.error-action a {
  min-width: 200px;
}
.error-card {
  box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
  max-width: 750px;
  width: 100%;
  background: #fff;
  margin: 64px auto 32px;
  text-align: center;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  padding: 16px 24px 42px;
}
.error-code {
  font-size: 6em;
  font-weight: 100;
}
