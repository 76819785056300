/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.tunjai-page-loading {
  min-height: 300px;
}
.tunjai-page-loading .ant-spin-blur {
  opacity: 0.4;
}
.tunjai-page-loading .ant-spin-dot i {
  background-color: #1890ff;
}
.tunjai-page-loading .ant-spin-text {
  font-size: 2em;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  margin-top: 14px;
  color: #1890ff;
}
#tunjai_content {
  position: relative;
  width: fit-content;
  min-width: 100%;
}
