/*
 * My Card
 * 1. mycard - Normal and base
 * 2. mycard-with-details - Header + Details as list
 * 3. mycard-columns - Card with Left, Right column
 */
.mycard {
	position : relative;

	.tablet & {
		.ant-card-body {
			padding : 12px;
		}
	}

	.mycard-header {
		margin-bottom: 16px;
		font-family: @font-family-title;
		.mycard-image {
			margin-bottom : 8px;
		}
		.title {
			font-size : 1.2em;
			font-weight: 600;
			color : @primary-color;
		}
		.subtitle {
			color : @info-color;
		}
	}
	.mycard-button-action {
		position : absolute;
		top : 8px;
		right : 8px;
		z-index : 1;
	}
	.mycard-tags {
		margin-top : 4px;
		.ant-tag {
			margin-right : 4px;
		}
	}
	.mycard-invalid-status {
		position: absolute;
		bottom : 0px;
		left : 4px;
		font-size : 0.85em;
		color : @gray-6;
	}


	&.ant-card {
		border-radius: @border-radius-base;
	}

	&.mycard-sm .ant-card-body {
		padding : 16px 16px;
		.mycard-button-action {
			top: 0;
			right : 0;
		}
		ul.mycard-details {
			font-size : 0.9em;
			li {
				margin-bottom : 1px;
			}
		}
	}

	&.mycard-inactive {
		background-image: repeating-linear-gradient(45deg,
			@gray-3 ,
			@gray-3 20px,
			transparent 20px,
			transparent 35px,
		);
	}
	&.mycard-disabled {
		background-color: @gray-3;
	}
	&.mycard-selected {
		background-color: @green-2;
	}

	&.ant-card-hoverable:hover {
		box-shadow : 0 2px 8px fade(@primary-color,35%);
	}
}



.mycard-with-details {
	.mycard-header {
		text-align: center;
	}
	ul.mycard-details {
		list-style: none;
    	padding: 0;
		margin: 8px 0 0;

		> li {
			margin-bottom : 8px;
			.title {
				color : @primary-color;
				font-size : 1em;
			}
			.subdetail {
				margin-top : 2px;
				margin-bottom : 12px;
				font-size : 0.9em;
			}

			.label {
				color : @info-color;
				display: inline-block;
				min-width : 120px;
				&::after {
					content : " : ";
				}
			}
			.value {
				display : inline-block;
			}

			&.separator {
				border:none;
				border-top :1px solid @gray-6;
				margin : 16px 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
			.anticon {
				color: @primary-color;
				padding-right : 4px;
			}
			a {
				text-decoration: underline dashed;
			}
		}
	}
}

.mycard-columns {
	.mycard-content {
		display : flex;
		flex-direction: row;
		flex-wrap : nowrap;
		align-content: stretch;
	}
	.mycard-header {
		margin-right : 16px;
		margin-bottom : 0;
		align-self: center;
		.mycard-image {
			margin-bottom : 0;
		}
	}
	ul.mycard-details {
		flex-grow: 1;
		margin-top : 0;
	}
	.mycard-side-actions {
		align-self: center;
	}
}

.mycard-list {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	position: relative;
	.mycard {
		margin : 0 8px 8px 0;
		min-width : 250px;
		@media(max-width:@screen-mobile) {
			width : 100%;
			margin : 0 0 12px;
			flex-basis: auto;
		}
	}

	.ant-empty {
		margin-left : auto;
		margin-right : auto;
	}
	&.mycard-list-striped > *:nth-child(even) {
		background-color : @gray-2;
	}
	&.mycard-list-vertical {
		flex-direction : column;
		&.mycard-list-line > * {
			border-bottom : 1px solid @border-color-base;
			&:last-child {
				border-bottom : none;
			}
		}
	}

	&.mycard-list-grid {
		align-items : stretch;
		.mycard {
			margin : 0;
			border-radius: 0;
			flex-basis: 33.33%;
			min-width: unset;
			.ant-card-body , .mycard-content{
				height : 100%;
			}

			@media(min-width : @screen-mobile + 1) and (max-width:@screen-notebook) {
				flex-basis: 50%;
			}
			@media(max-width:@screen-mobile) {
				width : 100%;
				margin-bottom : 8px;
				flex-basis: auto;
			}
		}
	}
}


.mycard-popover {
	.ant-popover-inner-content {
		padding : 0;
		.mycard {
			width : 300px;
		}
	}
}

.mycard-select-card {
	font-size : 0.95em;
	font-weight: normal !important;
	label {
		font-weight: 500 !important;
		color : @primary-color;
	}
	.card-title {
		font-family: @font-family-title;
		font-size : 1em;
		color : @primary-color;
		font-weight: 500 !important;
	}
	.card-detail {
		font-size : 0.9em;
	}

	&.with-image {
		display : flex;
		flex-wrap : nowrap;
		align-items: center;
		.col-image {
			margin-right : 6px;
		}
		.col-content {
			white-space: normal;
			flex-grow: 1;
			line-height: 1.5;
		}
	}
}

.mycard-ant-list.ant-list{
	min-height : 166px;
	> .ant-spin-nested-loading {
		min-height : 166px;
	}
	.ant-list-item {
		padding : 0;
	}
	.load-more-pane {
		text-align : center;
		margin : 12px 0;
	}
}
