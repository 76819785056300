/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app-brand {
  position: relative;
  padding: 8px 0;
  overflow: hidden;
  white-space: nowrap;
}
.app-brand .brand-logo {
  margin: 0 16px;
}
.app-brand .brand-title {
  display: block;
  margin-top: 16px;
  text-align: center;
  align-items: center;
  line-height: 48px;
  font-size: 2em;
  font-weight: 600;
  color: #D22027;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
}
.app-brand .brand-title-collapsed {
  margin-top: 0 16px;
  text-align: center;
  align-items: center;
  line-height: 48px;
  font-size: 1.2em;
  font-weight: 600;
  color: #D22027;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
}
.company-profile {
  background-color: #499c91;
  color: #fff;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  padding: 20px 12px;
  margin: 20px auto 20px;
  border-radius: 4px;
  width: calc(250px - 36px);
}
.company-profile .card-title {
  font-size: 1.2em;
  font-weight: normal !important;
  margin-bottom: 2px;
  margin-left: 4px;
}
.company-profile .card-title a {
  color: #fff;
  text-decoration: none !important;
}
.company-profile .card-image .image-loader {
  background-color: white;
}
.tunjai-menu {
  margin-bottom: 24px;
}
.tunjai-menu .ant-menu-item {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.tunjai-menu .ant-menu-item:hover {
  background-color: #fff2f0;
}
footer {
  font-size: 0.8em;
  color: #595959;
  text-align: right;
  padding-right: 8px;
  padding-bottom: 8px;
}
